//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Modal from './Modal.vue';
import constant from '~/const';

import tableConfig from './tableConfig';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      tableConfig,
      loader: false,
      search: '',
      constant,
      editingToken: {
        modal: false,
        data: {},
        isCreating: false,
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentsAndCurrenciesTokens', ['page', 'pages', 'data', 'sort']),

    preparedData() {
      const { data } = this;
      const search = this.search.toLowerCase();
      const filteredData = data.filter((e) => (
        e.currency.toLowerCase().includes(search) || e.title.toLowerCase().includes(search)
      ));
      return filteredData;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('paymentsAndCurrenciesTokens', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createTokenAction: 'createToken',
      editTokenAction: 'editToken',
    }),
    ...mapActions('currencies', {
      loadCommonCurrencies: 'getCurrencies',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    editToken(row) {
      this.editingToken.modal = true;
      this.editingToken.data = { ...row };
      this.editingToken.isCreating = false;
    },

    createToken() {
      this.editingToken.modal = true;
      this.editingToken.data = {};
      this.editingToken.isCreating = true;
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingToken;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create token?'
          : 'Are you sure you want to edit token?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              await this.createTokenAction(input);
            } else {
              await this.editTokenAction(input);
            }
            this.editingToken.modal = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Token created');
            } else {
              this.setSuccessNotification('Token edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },
  },
};

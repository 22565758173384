export default [
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'slot',
      name: 'title',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'enabled',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Decimals',
    },
    cell: {
      type: 'text',
      value: (e) => e.decimalPlaces,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'text',
      value: (e) => e.fee,
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Contract Address',
    },
    cell: {
      type: 'text',
      value: (e) => e.contractAddress,
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: 'Aggregating Minimum',
    },
    cell: {
      type: 'slot',
      name: 'aggregatingMin',
    },
  },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0"},[_c('v-text-field',{staticStyle:{"width":"200px"},attrs:{"clearable":"","dense":"","outlined":"","label":_vm.$t('Search'),"placeholder":" "},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.createCurrency}},[_vm._v(" "+_vm._s(_vm.$t('Add currency'))+" ")])],1)],1)],1),_c('div',[_c('UiTable',{attrs:{"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"623px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[(row.id)?_c('div',{staticClass:"mr-3"},[(_vm.getIconUrl(row))?_c('img',{staticClass:"d-flex section__icon",staticStyle:{"width":"32px"},attrs:{"src":_vm.getIconUrl(row)}}):_vm._e()]):_vm._e(),_c('div',[_c('div',{staticStyle:{"line-height":"1.25"}},[_vm._v(" "+_vm._s(row.name)+" ")]),(row.id)?_c('div',{staticClass:"text--disabled",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(row.title)+" ")]):_vm._e()])])]}},{key:"isInUse",fn:function(ref){
var row = ref.row;
return [(row.isInUse)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"isP2PEnabled",fn:function(ref){
var row = ref.row;
return [(row.isP2PEnabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"decimals",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.decimals)+" ")]}},{key:"fees",fn:function(ref){
var row = ref.row;
return _vm._l((row.fees),function(fee){return _c('div',{key:fee.id},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Fee:'))+" "+_vm._s(fee.charge)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$t('Details:'))+" "+_vm._s(fee.description)+" ")])])})}},{key:"currencyType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.currencies.CURRENCY_TYPE_LIST, row.currencyType))+" ")]}},{key:"additional",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[(row.isAssets)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('Is Assets'))+" ")]):_vm._e(),(row.isCheapFee)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('Is Cheap Fee'))+" ")]):_vm._e()])])]}},{key:"editActions",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('span',{staticClass:"editCurrency blue--text",on:{"click":function($event){return _vm.editCurrency(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Currency')))]),_c('span',{staticClass:"editFee blue--text ml-2",on:{"click":function($event){return _vm.editFee(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Fee')))])])]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingCurrency.data,"is-creating":_vm.editingCurrency.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingCurrency.modal),callback:function ($$v) {_vm.$set(_vm.editingCurrency, "modal", $$v)},expression:"editingCurrency.modal"}}),_c('ModalFee',{attrs:{"data":_vm.editingFee.data},on:{"apply":_vm.applyEditFee},model:{value:(_vm.editingFee.modal),callback:function ($$v) {_vm.$set(_vm.editingFee, "modal", $$v)},expression:"editingFee.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ModalFee.vue?vue&type=template&id=0594ceb5&scoped=true&"
import script from "./ModalFee.vue?vue&type=script&lang=js&"
export * from "./ModalFee.vue?vue&type=script&lang=js&"
import style0 from "./ModalFee.vue?vue&type=style&index=0&id=0594ceb5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0594ceb5",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0"},[_c('v-text-field',{staticStyle:{"width":"200px"},attrs:{"clearable":"","dense":"","outlined":"","label":this.$t('Search'),"placeholder":" "},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.createToken}},[_vm._v(" "+_vm._s(_vm.$t('Add Token'))+" ")])],1)],1)],1),_c('div',[_c('UiTable',{attrs:{"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"fixed-header":"","height":"623px"},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('div',{staticStyle:{"line-height":"1.25"}},[_vm._v(" "+_vm._s(row.currency)+" ")])])])]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('div',{staticStyle:{"line-height":"1.25"}},[_vm._v(" "+_vm._s(row.title)+" ")])])])]}},{key:"enabled",fn:function(ref){
var row = ref.row;
return [(row.enabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]}},{key:"aggregatingMin",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(_vm._s(row.aggregatingMin))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editToken(row)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingToken.data,"is-creating":_vm.editingToken.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingToken.modal),callback:function ($$v) {_vm.$set(_vm.editingToken, "modal", $$v)},expression:"editingToken.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      input: {},
      icon: null,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        name: '',
        title: '',
        symbol: '',
        isInUse: true,
        isP2PEnabled: false,
        currencyType: 0,
        decimals: '0',
        sortId: '0',
        cmcId: '0',
        aliases: [],
        isAssets: false,
        isCheapFee: false,
      };
    },

    isApplyDisabled() {
      return !(
        this.input.name
        && this.input.title
        && this.input.symbol
        && (this.input.decimals || this.input.decimals === 0)
        && (this.input.sortId || this.input.sortId === 0)
        && (this.input.cmcId || this.input.cmcId === 0)
      );
    },

    aliasesModel: {
      get() {
        const { aliases } = this.input;
        try {
          return aliases.join(' / ');
        } catch (error) {
          return '';
        }
      },
      set(value) {
        if (value.trim()) {
          const aliasesArr = value
            .split('/')
            .map((e) => e.trim());
          this.$set(this.input, 'aliases', aliasesArr);
        } else {
          this.$set(this.input, 'aliases', null);
        }
      },
    },

  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        this.icon = null;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
        } else {
          const model = { currencyId: data.id };
          Object.keys(defaultInput).forEach((key) => {
            if (key === 'aliases' && typeof data.aliases === 'string') {
              try {
                model[key] = JSON.parse(data.aliases);
              } catch (error) {
                model[key] = null;
              }
            } else {
              model[key] = data[key];
            }
          });
          this.$set(this, 'input', model);
        }
      }
    },
  },

  methods: {
    apply() {
      const { input, icon } = this;
      this.$emit('apply', {
        input: {
          ...input,
          aliases: input.aliases,
        },
        icon,
      });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    // isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      icon: null,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    input() {
      const { data } = this;
      return {
        ...(data?.fees?.length ? { id: data.fees[0].id } : { currencyId: data.currencyId }),
        charge: data?.fees?.length ? data.fees[0].charge : data.charge,
        description: data?.fees?.length ? data.fees[0].description : data.description,
      };
    },
    isCreating() {
      const { data } = this;
      return !data?.fees?.length;
    },
  },

  methods: {
    apply() {
      const { input } = this;
      input.charge = +input.charge;
      this.$emit('apply', { input });
    },
  },
};

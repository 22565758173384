//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import PaymentsAndCurrenciesCurrencies from '~/views/PaymentsAndCurrenciesCurrenciesAndTokens/PaymentsAndCurrenciesCurrencies/PaymentsAndCurrenciesCurrencies.vue';
import PaymentsAndCurrenciesTokens from '~/views/PaymentsAndCurrenciesCurrenciesAndTokens/PaymentsAndCurrenciesTokens/PaymentsAndCurrenciesTokens.vue';

export default {
  components: {
    PaymentsAndCurrenciesCurrencies,
    PaymentsAndCurrenciesTokens,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadCurrencies(),
      this.loadCryptoCurrencies(),
      this.loadCryptoTokens(),
      this.loadTokens(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),
    ...mapActions('paymentsAndCurrenciesCurrencies', {
      loadCurrencies: 'loadData',
    }),
    ...mapActions('paymentsAndCurrenciesTokens', {
      loadTokens: 'loadData',
    }),
  },
};
